.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.canvas-img {
  max-width: 54px;

    right: 0px;
    margin-top: -3px;

}
.invalid-feedback{
  display: block !important;
}
.single-disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(38 44 50/0.6);
  /* border-radius: 6px; */
  width: 100%;
  height: 100%;
}
.overlay-match {
  background: rgb(90 94 98/1);
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  opacity: 0.8;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7857142857142857rem;
}

.overlay-match-outer {
  background: rgb(90 94 98/1);
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  opacity: 0.8;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7857142857142857rem;
  right: 0.5%;
}

.spark-back {
  animation: sparkBack 0.6s ease;
}

@keyframes sparkBack {
  0% {
    background-color: #72bbef;
    color: #1e1e1e;
  }

  50% {
    background-color: #f8e71c;
    color: #fff;
  }

  100% {
    background-color: #72bbef;
    color: #1e1e1e;
  }
}
.spark-lay {
  animation: sparkLay 0.6s ease;
}

@keyframes sparkLay {
  0% {
    background-color: #faa9ba;
    color: #1e1e1e;
  }

  50% {
    background-color: #26f1f8;
    color: #fff;
  }

  100% {
    background-color: #faa9ba;
    color: #1e1e1e;
  }
}

.back-active {
  background-color: rgb(110, 185, 238) !important;
}
.lay-active {
  background-color: rgb(247, 137, 161) !important;
}
.text-green {
  color: green !important;
}
.text-red {
  color: red !important;
}
.team-red-score {
  font-size: 0.7142857142857143rem;
 
  color: #e20000;
  font-weight: 700;
}
.team-green-score {
  font-size: 12px;
  color: green;
  font-weight: 700;
}


.loading-bet {
  position: absolute;
  z-index: 9999999999999;
  /* min-height: 434px; */
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(38 44 50/0.8);
  border-radius: 0.75rem 0.75rem 0px 0px;
}



.social-header a {
  /* background: #0000007a; */
  /* padding: 7px 11px !important; */
  text-decoration: none;
  margin-right: 13px;
  border-radius: 5px;
  color: #fff;
  border: 4px solid #252525;
  /* font-family: "Inter"; */
  font-weight: 600;
  /* display: flex; */
  /* align-items: center; */
  font-size: 13px;
}

.social-header a img {
  vertical-align: text-bottom;
  height: 16px;
}

.social-header a:last-child {
  margin: 0;
}

.social-header a.login {
  /* background: #18b0c8; */
  background: transparent;
  padding: 2px 6px;
  color: #fff;
}

.social-header a.signup {
  padding: 2px 6px;
}
